export const rules = {
  geos: [
    {
      required: true,
      message: '请选择地区',
    },
    {
      validator(rule, value, callback) {
        if (value.length == 0) {
          return callback(new Error('请选择地区'));
        }
        callback();
      },
      trigger: ['blur'],
    },
  ],
  minAge: [
    {
      required: true,
      message: '请选择最小年龄',
    },
  ],
  maxAge: [
    {
      required: true,
      message: '请选择最大年龄',
    },
  ],
  audienceSize: [
    {
      validator(rule, value, callback) {
        if (!value.success) {
          return callback(new Error(value.error ? value.error : '正在确认受众范围'));
        }
        callback();
      },
      trigger: ['change', 'blur'],
    },
  ],
};
