<template>
  <div
    class=""
    v-loading="loading"
  >
    <!-- <div><i class="el-icon-s-custom"></i>覆盖范围</div> -->
    <div>
      <span class="text-red-600">{{ value?.minimum }}万人</span> -
      <span class="text-red-600"> {{ value?.maximum }}万人</span>
    </div>
    <!-- <div class="text-red-600">{{ value.error }}</div> -->
  </div>
</template>
<script>
import { getAudienceSize } from '@/api/snapchat/create.js';
import _ from 'lodash';
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    audience: Object,
    data: Object,
  },
  data() {
    this.getAudienceSizeDebounce = _.debounce(this.getAudienceSize, 1000);
    return {
      loading: false,
      index: 0,
    };
  },
  methods: {
    getAudienceSize() {
      console.log('getAudienceSize', this.data,this.audience);
      const { audienceSize, ...data } = this.audience;
      if (!data.geos || data.geos.length == 0) return;
      data.geos = data.geos.map((item) => {
        console.log(item, 'item');
        if (typeof item == 'object') {
          return item;
        } else {
          return {
            countryCode: item,
          };
        }
      });
      data.interests = data.interests
        ? [
            {
              categoryId: Array.from(new Set([].concat(...Object.values(data.interests)))),
              operation: 'INCLUDE',
            },
          ]
        : [];

      this.loading = true;
      let params = {
        accountId: this.data.adAccountId,
      };
      this.index += 1;
      const index = this.index;
      this.$emit('input', { minimum: 0, maximum: 0, success: false });
      getAudienceSize(data, params)
        .then((res) => {
          if (res.code == 0 && index == this.index) {
            const { minimum, maximum, success, error } = res.data;
            this.$emit('input', {
              minimum: (minimum / 10000).toFixed(2),
              maximum: (maximum / 10000).toFixed(2),
              success,
              error,
            });
          }
        })
        .finally(() => {
          if (index == this.index) {
            this.loading = false;
          }
        });
    },
  },
  watch: {
    audience: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        const { audienceSize: newAudienceSize, ...newParams } = val || {};
        const { audienceSize: oldAudienceSize, ...oldParams } = oldVal || {};
        if (JSON.stringify(newParams) != JSON.stringify(oldParams)) {
          this.$emit('input', { minimum: 0, maximum: 0, success: false });
          this.getAudienceSizeDebounce();
        }
      },
    },
  },
};
</script>
