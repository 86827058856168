<template>
  <div class="bg-white relative">
    <div
      class="m-6 pr-24"
      :key="index"
    >
      <el-form
        :model="audience"
        label-width="120px"
        :rules="rules"
        ref="form"
        :disabled="nextEdit && disabled"
      >
        <el-form-item
          label="地区"
          prop="geos"
        >
          <div class="flex">
            <el-select
              v-model="audience.geos"
              class="flex-1"
              :disabled="isLaunch"
              multiple
              filterable
              default-first-option
              @change="() => onChangeGeos(audience.geos)"
            >
              <el-option-group
                v-for="country in countryList"
                :key="country.code"
                :label="country.name"
              >
                <el-option
                  v-for="item in country.children"
                  :key="item.code"
                  :label="item.name"
                  :value="item.myCode"
                >
                </el-option>
              </el-option-group>
            </el-select>
            <el-button
              type="text"
              v-if="!edit"
              @click="autoFillGeos"
              class="!ml-2"
              >自动填充</el-button
            >
          </div>
        </el-form-item>

        <el-form-item
          label="年龄"
          required
        >
          <div class="flex">
            <el-form-item prop="minAge">
              <el-select
                v-model="audience.minAge"
                @change="(val) => ageChange('minAge', val, audience)"
                clearable
              >
                <el-option
                  v-for="age in minAgeList"
                  :label="age.name"
                  :key="age.name"
                  :value="age.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <div class="mx-6">~</div>
            <el-form-item prop="maxAge">
              <el-select
                v-model="audience.maxAge"
                @change="(val) => ageChange('maxAge', val, audience)"
                clearable
              >
                <el-option
                  v-for="age in ageList"
                  :label="age.name"
                  :key="age.name"
                  :value="age.value"
                ></el-option
              ></el-select>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item
          label="性别"
          prop="gender"
        >
          <el-radio-group
            v-model="audience.gender"
            @input="(val) => setAudience({ gender: val })"
          >
            <el-radio-button
              v-for="gender in enumConstants.genders"
              :key="gender.key"
              :label="gender.key"
              >{{ gender.value }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item label="语言">
          <el-select
            multiple
            class="w-full"
            filterable
            default-first-option
            v-model="audience.languages"
            @change="(val) => setAudience({ languages: val })"
          >
            <el-option
              v-for="languages in enumConstants.languages"
              :label="languages.value"
              :value="languages.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预设受众">
          <predefinedAudiences
            v-model="audience.interests"
            :list="predefinedAudienceList[index]"
            :interestLabelQueryList="interestLabelQueryList[index] || []"
            :interestLabelSaveAPI="interestLabelSaveAPI"
            v-loading="predefinedAudienceLoading[index]"
            :countryCodes="audience.geos"
            :adAccountId="data.adAccountId"
          ></predefinedAudiences>
        </el-form-item>

        <el-form-item label="自定义受众">
          <customAudience
            v-model="audience.segments"
            :list="customAudienceList"
            v-loading="customAudienceLoading"
          ></customAudience>
        </el-form-item>
        <el-form-item label="自动扩展受众">
          <el-checkbox v-model="audience.autoExpansionOptions">自动扩展受众</el-checkbox>
        </el-form-item>
        <el-form-item
          prop="audienceSize"
          label="受众范围"
        >
          <audienceSize
            v-model="audience.audienceSize"
            :audience="JSON.parse(JSON.stringify(audience))"
            :data="data"
            @input="onChangeAudienceSize(index)"
          ></audienceSize>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { customAudienceQuery, interestLabelSave } from '@/api/addGoods';
import { queryTreeCountry, queryInterestByCountry } from '@/api/snapchat/create';
import { minAgeList, ageList } from '@/views/snapchat/create/index.js';
import predefinedAudiences from '@/views/snapchat/components/predefinedAudiences/index.vue';
import customAudience from '@/views/snapchat/components/customAudience/index.vue';
import audienceSize from '@/views/snapchat/create/components/audience/audienceSize/index.vue';
import _ from 'lodash';
import { rules } from '@/views/snapchat/create/components/audience/index.js';
import moment from 'moment';
export default {
  components: { predefinedAudiences, customAudience, audienceSize },
  props: {
    data: Object,
    enumConstants: Object,
    index: {
      type: Number,
      default: 0,
    },
    target: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    nextEdit: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    this.getPredefinedAudienceListDebounce = _.debounce(this.getPredefinedAudienceList, 300);
    return {
      audience: {},
      ageList,
      minAgeList,
      interestLabelSaveAPI: interestLabelSave,
      predefinedAudienceList: [], // 预设受众列表
      predefinedAudienceLoading: [], //预设受众接口
      customAudienceList: [], // 自定义受众
      customAudienceLoading: false, // 自定义受众
      countryList: [], // 地区列表
      interestLabelQueryList: [], // 常用
      rules,
      errList: [], // 验证不通过列表
    };
  },
  computed: {
    editData() {
      if (this.edit) {
        return this.data.ad;
      } else {
        return this.data;
      }
    },
    isLaunch() {
      if (!this.edit) {
        return false;
      }
      const currentTime = moment();
      const targetTime = moment(this.startTime);

      return targetTime.isBefore(currentTime);
    },
  },
  methods: {
    // 获取预设受众列表
    getPredefinedAudienceList(val, index) {
      // this.predefinedAudienceLoading = true;
      if (!val || !val.length) {
        return;
      }
      this.$set(this.predefinedAudienceLoading, index, true);
      queryInterestByCountry(val)
        .then((res) => {
          if (res.code == 0) {
            this.$set(this.predefinedAudienceList, index, res.data);
          }
        })
        .finally(() => {
          this.$set(this.predefinedAudienceLoading, index, false);

          // this.predefinedAudienceLoading = false;
        });
    },
    // 获取自定义受众列表
    getCustomAudienceList(index) {
      let params = {
        accountId: this.data.adAccountId,
      };
      this.customAudienceLoading = true;
      customAudienceQuery(params)
        .then((res) => {
          if (res.code == 0) {
            this.customAudienceList = res.data.map((item) => ({ ...item, name: item.key }));
          }
        })
        .finally(() => {
          this.customAudienceLoading = false;
        });
    },
    // 自动填充
    autoFillGeos() {
      let val = [this.data.promotionInfo.site.countryCode];
      //   this.$set(this.value[index], 'geos', val);
      this.onChangeGeos(val);
    },
    // 国家
    getCountryList() {
      queryTreeCountry().then((res) => {
        this.countryList = res.data;
      });
    },
    // 修改地区
    onChangeGeos(val) {
      this.setAudience({ geos: val }, this.index);
      this.getPredefinedAudienceListDebounce(val, this.index);
    },
    setAudience(v) {
      this.$emit('setAudience', v, this.index);
    },
    // 对外验证
    validate() {
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.validate().catch(() => index));
      });
      return new Promise((resolve, reject) => {
        Promise.allSettled(list).then((res) => {
          res.map((item) => {
            if (typeof item.value == 'number') {
              this.errList.push(item.value);
              reject();
            }
          });
          resolve();
        });
      });
    },
    clearValidate() {
      if (!this.$refs.form) return;
      this.$refs.form.clearValidate();
      // let list = [];
      // this.errList = [];
      // this.$refs.form.map((form, index) => {
      //   list.push(form.clearValidate());
      // });
      // return Promise.all(list);
    },
    onChangeAudienceSize(index) {
      if (this.$refs.form && this.$refs.form[index]) {
        this.$refs.form[index].validate().then(() => {
          this.errList = _.without(this.errList, index);
        });
      }
    },
    ageChange(key, val, audience) {
      switch (key) {
        case 'minAge':
          if (val > audience.maxAge) {
            this.$set(audience, 'maxAge', val);
            this.setAudience({ maxAge: val });
          }
          break;
        case 'maxAge':
          if (val < audience.minAge) {
            this.$set(audience, 'minAge', val);
            this.setAudience({ minAge: val });
          }
      }
    },
  },
  created() {
    console.log('created======');

    this.getCountryList();
  },
  watch: {
    // 监听账户改变，需要自定义受众列表
    'data.adAccountId': {
      handler(val, oldVal) {
        if (val != oldVal && val) {
          this.getCustomAudienceList();
        }
      },
    },
    target: {
      handler(val) {
        this.audience = val;
      },
      deep: true,
      immediate: true,
    },
    'audience.id': {
      handler(newVal, oldVal) {
        if (this.nextEdit && newVal !== oldVal) {
          this.onChangeGeos(this.audience.geos);
          this.getCustomAudienceList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
